<template>
  <div class="molecules-carousel-agents">
    <client-only>
      <swiper-container
        ref="swiper"
        slides-per-view="auto"
        init="false"
        class="overflow-hidden"
        :style="{
          '--swiper-pagination-bottom': '0'
        }"
      >
        <swiper-slide
          v-for="(agent, agetIndex) in agents"
          :key="agetIndex"
          class="exact-w-[282px] relative drop-shadow-sm-2 !inline-flex flex-col items-start"
        >
          <atoms-card class="text-center text-dark h-[21.75rem] py-6 !px-4">

            <div
              class="mx-auto w-[6.125rem] h-[6.125rem] rounded-full flex items-center justify-center"
              :class="{
                'bg-primary': agent.color === 'primary',
                'bg-[#1183C5]': agent.color === 'blue',
                'bg-[#FFB10A]': agent.color === 'yellow',
                'bg-[#E83F6F]': agent.color === 'pink'
              }"
            >
              <AtomsImage
                :src="agent.img"
                class="max-w-full w-full h-full mx-auto"
              />
            </div>

            <p class="text-center font-extrabold mt-2">{{  agent.name  }}</p>
            <p class="text-xs text-blue-500 font-bold mt-2">{{ agent.notice }}</p>
            <p class="text-xs font-bold mt-2">{{ agent.title }}</p>
            <p class="text-xs mt-2">{{ agent.content }}</p>
            <NuxtImg
              src="/icons/trustpilot/5stars.svg"
              alt="trustpilot"
              width="130"
              height="24"
              loading="lazy"
              class="inline-block w-[130px] h-[24px] mt-4"
            />
          </atoms-card>
        </swiper-slide>
      </swiper-container>
    </client-only>
  </div>
</template>

<script setup>
import { register } from 'swiper/element/bundle'
register()

defineOptions({
  name: 'MoleculesCarouselAgents'
})

const swiper = ref()

const agents = [
  {
    img: 'https://asset.compareclub.com.au/health-insurance/experts/live-chat-agent_becca_young.svg',
    name: 'Becca Young',
    notice: 'Helped over 592 people',
    title: 'Becca was very informative',
    content: '"My consultation with Becca was amazing. She was understanding and went through everything I asked and more."',
    color: 'pink'
  },
  {
    img: 'https://asset.compareclub.com.au/health-insurance/experts/live-chate-agent_Matt_DeGenov.svg',
    name: 'Matt DeGenova',
    notice: 'Helped over 370 people',
    title: 'A very good review',
    content: '“I found Matt to be informative and helpful and he actually knew what he was talking about, and there was no pressure to change funds if I didn\'t want too”',
    color: 'primary'
  },
  {
    img: 'https://asset.compareclub.com.au/health-insurance/experts/live-chat-agent_Nathan_Veevers.svg',
    name: 'Nathan Veevers',
    notice: 'Helped over 419 people',
    title: 'Nathan was really helpful',
    content: '“Both Nathan and the other young man I spoke to were very helpful and professional when dealing with me”',
    color: 'yellow'
  },
  {
    img: 'https://asset.compareclub.com.au/health-insurance/experts/live-chat-agent_Anita_Jackson.svg',
    notice: 'Helped over 687 people',
    name: 'Anita Jackson',
    title: 'Anita was helpful',
    content: '“ was connected to Anita Jackson who was extremely helpful and guided me into my decision to transfer to AHM.”',
    color: 'blue'
  }
]

onMounted(async () => {
  await nextTick()

  const el = swiper.value

  if (el) {
    const params = {
      autoplay: {
        delay: 5000
      },
      injectStyles: [`
        :host .swiper-pagination {
          height: 8px;
        }

        :host .swiper-pagination-bullet-active {
          background: #0ac26a !important;
        }

        :host .swiper-pagination-bullet {
          position: relative;
          top: -9px;
          background: #fff
        }
        
        :host .swiper {
          padding: 0 16px 35px;
          width: calc(100% - 32px);
        }

        @media only screen and (min-width: 980px) and (max-width: 1680px) {
          :host .swiper {
            max-width: 1212px;
            overflow: initial;
          }
        }

        @media only screen and (min-width: 1681px) {
          :host .swiper {
            max-width: 1212px;
            margin-left: auto !important;
            margin-right: auto !important;
          }
        }
      `],
      pagination: {
        clickable: true
      },
      spaceBetween: 22,
      breakpoints: {
        980: {
          slidesPerGroup: 4
        }
      }
    }

    Object.assign(el, params)
    el.initialize()
  }
})
</script>

