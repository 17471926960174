<template>
  <!-- TODO: GUIDES HANDLING -->
  <div
    class="molecules-cover-tiers bg-gray-10 pt-[2.8125rem] pb-10 md:py-16"
    :class="{
      'in-guides': inGuides
    }"
  >
    <!-- eslint-disable vue/no-v-html -->
    <p
      v-if="title"
      ref="title"
      class="molecules-cover-tiers__title max-w-wrapper-md mx-auto md:text-center text-2xl md:text-3.5xl/12 mb-4 px-4 lg:px-0"
      v-html="title"
    >
    </p>

    <p
      v-if="desc"
      class="max-w-wrapper-md mx-auto text-base md:text-lg/[1.625rem] mb-6 md:mb-8 md:text-center px-4 lg:px-0"
      v-html="desc"
    >
    </p>

    <client-only>
      <swiper-container
        ref="swiper"
        slides-per-view="auto"
        init="false"
        class="overflow-hidden"
        :style="{
          '--swiper-pagination-bottom': '0'
        }"
      >
        <swiper-slide
          v-for="(item, itemKey) in list"
          :key="itemKey"
          class="exact-w-[288px] relative drop-shadow-sm-2 !inline-flex flex-col items-start"
        >
          <div
            v-if="item.type"
            class="inline-block h-[3.125rem] px-4 pt-[0.375rem] rounded-t-2xl font-bold text-sm -mb-4"
            :class="{
              'bg-yellow-600': item.type.toLowerCase().includes('gold'),
              'bg-gray-350': item.type.toLowerCase().includes('silver'),
              'bg-brown-50 text-white': item.type.toLowerCase().includes('bronze'),
              'bg-cyan-600 text-white': item.type.toLowerCase().includes('basic'),
              'bg-primary text-white': item.type.toLowerCase().includes('ovhc')
            }"
          >
            {{ item.type }}
          </div>

          <div class="bg-white relative z-[2] rounded-[1.25rem] p-4 flex-1 w-full text-left">
            <div class="min-h-[9.5rem]">
              <p
                class="font-bold mb-2"
                v-html="item.title"
              >
              </p>

              <p
                class="text-sm"
                v-html="item.desc"
              >
              </p>
            </div>
          </div>
        </swiper-slide>
      </swiper-container>
    </client-only>
  </div>
</template>

<script setup>
import { register } from 'swiper/element/bundle'
register()

defineOptions({
  name: 'MoleculesCoverTiers'
})

defineProps({
  title: {
    type: String,
    default: ''
  },

  desc: {
    type: String,
    default: ''
  },

  list: {
    type: Array,
    default: () => ([])
  },

  /**
   * a flag that determines if this
   * component is being used inside contentful posts
   */
  inGuides: {
    type: Boolean,
    default: false
  },

  carouselOptions: {
    type: Object,
    default: () => ({})
  }
})

const swiper = ref(null)

onMounted(async () => {
  await nextTick()

  const el = swiper.value

  if (el) {
    const params = {
      injectStyles: [`
        :host .swiper-pagination {
          height: 8px;
        }

        :host .swiper-pagination-bullet-active {
          background-color: #475D80 !important;
        }

        :host .swiper-pagination-bullet {
          position: relative;
          top: -9px;
        }
        
        :host .swiper {
          padding: 0 16px 35px;
          width: calc(100% - 32px);
        }

        @media only screen and (min-width: 980px) and (max-width: 1680px) {
          :host .swiper {
            max-width: 928px;
            overflow: initial;
          }
        }

        @media only screen and (min-width: 1681px) {
          :host .swiper {
            max-width: 1568px;
            margin-left: auto !important;
            margin-right: auto !important;
          }
        }
      `],
      pagination: {
        clickable: true
      },
      spaceBetween: 32,
      breakpoints: {
        980: {
          slidesPerGroup: 4
        }
      }
    }

    Object.assign(el, params)
    el.initialize()
  }
})
</script>
